.single-post {
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
}

.post-page {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 3rem;
}

.comment-section {
    display: grid;
}
