.user-posts-page {
    padding: 2rem;
    height: 90%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.user-posts {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 700;
}
