.card-section {
    flex-grow: 4;
}

.avatar-span {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    padding-top: 0.5rem;
}

.comment {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.comment-border-link {
    display: block;
    position: relative;
    width: 12px;
    /* margin-left: 1rem; */
    height: calc(100% - 50px);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    background-color: rgba(0, 0, 0, 0.1);
    background-clip: padding-box;
}

.comment-border-link:hover {
    background-color: rgba(0, 0, 0, 0.3);
}


