.verses {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 3rem;
}

.verses-wrapper {
    /* overflow: auto; */
    /* position: fixed; */
    height: 90%;
    width: 100%;
}

.verse-display {
    padding: 0.5rem;
    border-radius: 500px;
}

.verse-display:hover {
    color: primary;
}
.verse-footer {
    padding-top: 1rem;
}
