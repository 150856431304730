.verse-page {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 3rem;
}

.verse-card {
    min-width: 500;
    min-height: 100;
}

.post-form {
    padding: 1rem;
    border-radius: 2rem;
}

.verse-wrapper {
    /* overflow: auto; */
    /* position: fixed; */
    height: 90%;
    width: 100%;
}

.comment-form {
    padding-top: 1rem;
    border-radius: 2rem;
    padding-bottom: 1rem;
}

.right-align-item {
    margin-left: 70%;
}

.right-align-post {
    /* width: 20% !important; */
    margin: 0 auto !important;
    display: flex !important;
    float: right !important;
}

.border-l {
    border-left: solid 5px;
}
