.login-page {
    align-items: center;
    justify-content: center;
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 10rem;
    position: relative;
}

label {
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;
}
