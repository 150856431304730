.home-page {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.home-title {
    padding: 5rem;
    padding-top: 8rem;
}
